h1 {
	padding-bottom: 0 !important;
}
h1::before {
	display: none !important;
}
.teaser-slider-item__date {
	background: #948f73 !important;
}
.teaser-slider-item__time {
	background: #164769 !important;
}
.teaser-slider-item__time::before {
	border-color: transparent #00263c transparent transparent !important;
}
